import axios from "axios";
// alert("sss")
const mytoken = localStorage.getItem("access_token");

if (window.location.host === "localhost:3000") {
  //  axios.defaults.baseURL = "https://pickypigsapi.herokuapp.com";
  // axios.defaults.baseURL = "https://apps.narola.online:5003";
  // axios.defaults.baseURL = "https://eros.narola.online:5003";
  // axios.defaults.baseURL = "https://api.pickypigs.com:5003";

  // axios.defaults.baseURL = "http://192.168.100.39:5003";
  // axios.defaults.baseURL = "http://103.232.124.169:11014"; // * Live API
  // axios.defaults.baseURL = "http://192.168.1.102:5005"; // * Ajay Local API
  // axios.defaults.baseURL = "http://139.59.40.108:11025"; // * Live API
  axios.defaults.baseURL = "https://api.pickypigs.com"; // * Live API
  // axios.defaults.baseURL = "http://192.168.1.213:5003"; // *suchit pc Local API
} else {
  // axios.defaults.baseURL = "https://pickypigsapi.herokuapp.com";
  // axios.defaults.baseURL = "http://192.168.100.39:5003";
  // axios.defaults.baseURL = "https://apps.narola.online:5003";
  // axios.defaults.baseURL = "https://eros.narola.online:5003";
  // axios.defaults.baseURL = "https://api.pickypigs.com:5003";
  // axios.defaults.baseURL = "http://103.232.124.169:11014"; // * Live API
  // axios.defaults.baseURL = "http://192.168.1.102:5005"; // * Ajay Local API
  // axios.defaults.baseURL = "http://139.59.40.108:11025"; // * Live API
  axios.defaults.baseURL = "https://api.pickypigs.com"; // * Live API
  // axios.defaults.baseURL = "http://192.168.1.213:5003"; // *suchit pc Local API

  //api live server
}
if (mytoken && mytoken)
  axios.defaults.headers.common = { "x-access-token": mytoken };

export default axios;
