import Axios from "./axios";
import { setAlert } from "./alertAction";
import { logoutUser } from "./generalActions";

export const getUserProfileDetail = (
  history,
  redirectFlag = "signIn",
  redirectPathName = ""
) => {
  console.log("history", history);
  let pathName = window.location.pathname;

  return async (dispatch) => {
    try {
      dispatch({ type: "GET_USERPROFILE_REQUEST" });
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      let dataURL = `/frontend/profile`;
      let response = await Axios.get(dataURL, config);
      console.log('response.data.dishList************************************',response.data.userDetail[0].dishList)
      if(response.data?.userDetail[0]?.dishList){
          dispatch({type:'SET_FAVORITE_DISH_LIST',payload:response.data.userDetail[0].dishList})
      }
      dispatch({ type: "GET_USERPROFILE_SUCCESS", payload: response.data });
      if (redirectFlag === "signup") {
        history && history.push({
          pathname: "/user_detail",
          state: { preferences: true, tab3: true, pathName: pathName },
        });
      } else if (redirectFlag === "UpdatePreferences") {
        history && history.push(redirectPathName);
        // history.push({
        //   pathname: "/user_detail",
        //   state: { preferences: true, pathName: pathName },
        // });
      }
    } catch (error) {
      dispatch({ type: "GET_USERPROFILE_FAILURE", payload: error });
      if (error.response) {
        dispatch(setAlert(`${error.response.data.message}`, "error"));
        if (error.response && error.response.status === 401) {
          dispatch(logoutUser(history));
        }
      } else {
        dispatch(setAlert("Something Went Wrong!", "error"));
      }
    }
  };
};

export const updateUserProfileDetail = (data, bool = false) => {
  let myPreferences = { myPreferences: data.myPreferences };
  return async (dispatch) => {
    try {
      dispatch({ type: "UPDATE_USERPROFILE_REQUEST" });
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      let dataURL = `/frontend/profile/`;
      let response = await Axios.put(
        dataURL,
        JSON.stringify(bool ? data : myPreferences),
        config
      );
      // console.log("my preferences", response);
      dispatch({ type: "UPDATE_USERPROFILE_SUCCESS", payload: response.data });
      dispatch(
        getUserProfileDetail(data.history, "UpdatePreferences", data.pathName)
      );
      dispatch(setAlert("Profile Updated Successfully", "success"));
    } catch (error) {
      dispatch({ type: "UPDATE_USERPROFILE_FAILURE", payload: error });
      if (error.response) {
        dispatch(setAlert(`${error.response.data.message}`, "error"));
      } else {
        dispatch(setAlert("Something Went Wrong!", "error"));
      }
    }
  };
};

export const updateUserProfileImage = (image) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "UPDATE_USERIMAGE_REQUEST" });
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const formData = new FormData();
      const file = image;
      formData.append("image", file);

      let dataURL = `/frontend/profile/upload_profile_image`;
      let response = await Axios.put(dataURL, formData, config);
      dispatch({ type: "UPDATE_USERIMAGE_SUCCESS", payload: response.data });
      dispatch(getUserProfileDetail());
      dispatch(setAlert("Profile Image Updated Successfully", "success"));
    } catch (error) {
      dispatch({ type: "UPDATE_USERIMAGE_FAILURE", payload: error });
      if (error.response) {
        dispatch(setAlert(`${error.response.data.message}`, "error"));
      } else {
        dispatch(setAlert("Something Went Wrong!", "error"));
      }
    }
  };
};
