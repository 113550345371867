import React,{ useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import './AlertSystemPage.scss';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { removeAlert } from "../../redux/actions/alertAction";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';


function Alert(props) {
    return <MuiAlert elevation={5} variant="standard" {...props} />;
  }



const AlertSystemPage=()=>{
    const dispatch=useDispatch();
    let alertInfo = useSelector((state) => {
        return state.alert;
    });

    const [open,setOpen] =useState(true);

    
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };

    return(
        <>
            {true?
                <section >
                    {
                        alertInfo.length > 0 ?
                            <React.Fragment>
                                {
                                    alertInfo.map((alert,index) => {
                                        if(alert.color==='error'){
                                            return (
                                                    <Snackbar  anchorOrigin={{ vertical: 'top', horizontal: 'right'}} open={open}  onClose={handleClose}  style={{marginTop:(54*index)}}  key={alert.id}>
                                                        <Alert  icon={<ErrorOutlineIcon style={{color:'#cb007b'}} />} onClose={()=>{dispatch(removeAlert(alert.id))}} severity={alert.color} style={{color:'#cb007b'}}> {alert.message}  </Alert>                                               
                                                    </Snackbar>
                                            )
                                        }
                                        
                                        if(alert.color==='success'){
                                        return (
                                                <Snackbar  anchorOrigin={{ vertical: 'top', horizontal: 'right'}} open={open}  onClose={handleClose}   style={{marginTop:(54*index)}}  key={alert.id}>
                                                    <Alert icon={<CheckCircleOutlineIcon style={{color:'#44E0C2'}} />} style={{color:'$44E0C2'}} onClose={()=>{dispatch(removeAlert(alert.id))}} severity={alert.color}> {alert.message}  </Alert>                                               
                                                </Snackbar>
                                        )
                                        }
                                    })
                                }

                                
                            </React.Fragment> : null
                    }
                </section>
            :
            null
            }
           
        </>
    )
}

export default AlertSystemPage;