import React from 'react';
import "./PageNotFound404MobilePage.scss"
import logo from "../../../assets/images/logo.svg"
import { Link } from 'react-router-dom';

const PageNotFound404MobilePage=()=>{

    return(
        <React.Fragment>
            <div className="container PageNotFound404MobilePage-container">
                <section className="PageNotFound404MobilePage-section">
                    <p className="num_404">4<span><img src={logo} className="img-fluid img_404" alt="logo" loading="lazy" /></span>4</p>
                    <p className="oops_404">Oopsie! &nbsp;<span className="missing_404">Something's missing</span></p>
                    <p className="looking_404" >We can't seem to find the page you're looking for...</p>
                    <Link to="/" className="back_to_home_404">Back To Home</Link>
                </section>
            </div>

        </React.Fragment>
    )
}

export default PageNotFound404MobilePage;

